<template>
<div id="">
  <v-container grid-list-xl>
    <v-layout  wrap justify-center>
      <v-flex md8 sm9 xs12>
        <h3 class="title about-us">{{$t('tenantFaq.tOrl')}}</h3>
      </v-flex>
        <v-container grid-list-sm>
          <v-layout  wrap justify-center>
            <v-flex xs12 sm6 class="pa-4 text-xs-center" v-for="(type, index) in types" :key="index">
              <v-btn color="primary" class="pa-4" :href="type.path"><v-icon>{{type.icon}}  </v-icon> {{type.name}}</v-btn>
            </v-flex>
          </v-layout>

        </v-container>
      </v-layout>

  </v-container>

</div>
</template>
<script>
export default {
  name: "",
  data: () => ({
    // types:[
    //   {
    //     path:"/faq/tenants",
    //     icon:"search",
    //     name:"FAQ for TENANTS"
    //   },
    //   {
    //     path:"/faq/landlords",
    //     icon:"meeting_room",
    //     name:"FAQ for LANDLORDS"
    //   },
    // ]
  }),
  computed:{
    types(){

      return [
        {
          path:"/faq/tenants",
          icon:"search",
          name:this.$t('tenantFaq.faqT')
        },
        {
          path:"/faq/landlords",
          icon:"meeting_room",
          name:this.$t('tenantFaq.faqLL')        },
      ]
    }
  },
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }
}
</script>
<style lang="scss" scoped>
.search-row {
    background: rgba(255,255,255,0),;
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
}
.fixedHeight{
  height:2em;
}
.blog-title {
    margin: auto;
    padding-top: 10vh;
    margin-bottom: 5vh;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,.6);
}
.huge{
  font-size:5em !important;
}
</style>
