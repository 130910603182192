<template>
<div id="">
  <v-layout wrap pa-3 justify-center>
    <v-flex md8 sm9 xs10>
      <v-breadcrumbs divider="">
        <v-icon slot="divider">chevron_right</v-icon>
        <v-breadcrumbs-item v-for="(item,n) in items" :key="n" :disabled="item.disabled">
          <a :href="item.href">{{ item.text }}</a>
        </v-breadcrumbs-item>
      </v-breadcrumbs>
      <div class="how-it-works">
        <h3 class="headline mt-3">1. {{$t('tenantFaq.title1')}} </h3>

        <h4 class="title mt-2">1.1. {{$t('tenantFaq.s11')}} </h4>
        <div>
          {{$t('tenantFaq.t111')}}
        </div>
        <div>
          <v-btn color="primary" flat href='/aboutUs'>
            {{$t('tenantFaq.l111')}}
          </v-btn>
        </div>
        <h4 class="title mt-2">1.2. {{$t('tenantFaq.s12')}} </h4>
        <div>
          {{$t('tenantFaq.t121')}}
        </div>



        <h4 class="title mt-2">1.3. {{$t('tenantFaq.s13')}} </h4>
        <div>
          {{$t('tenantFaq.t131')}}
        </div>
        <ul>
          <li>{{$t('tenantFaq.l132')}}</li>
          <li>{{$t('tenantFaq.l133')}}</li>
          <li>{{$t('tenantFaq.l134')}}</li>
          <li>{{$t('tenantFaq.l135')}}</li>
        </ul>
        <div>
          {{$t('tenantFaq.t136')}}
        </div>


        <h4 class="title mt-2">1.4. {{$t('tenantFaq.s14')}} </h4>
        <div>
          {{$t('tenantFaq.t141')}}
        </div>
        <h3 class="headline mt-3 mt-2">2. {{$t('tenantFaq.title2')}}</h3>
        <h4 class="title mt-2">2.1. {{$t('tenantFaq.s21')}} </h4>
        <div>
          {{$t('tenantFaq.t211')}}
        </div>
        <h4 class="title mt-2">2.2. {{$t('tenantFaq.s22')}} </h4>
        <div>
          {{$t('tenantFaq.t221')}}
        </div>
        <h4 class="title mt-2">2.3. {{$t('tenantFaq.s23')}} </h4>
        <div>
          {{$t('tenantFaq.t231')}}
        </div>
        <h4 class="title mt-2">2.4. {{$t('tenantFaq.s24')}} </h4>
        <div>
          {{$t('tenantFaq.t241')}}
        </div>
        <h3 class="headline mt-3">3. {{$t('tenantFaq.title3')}}</h3>
        <h4 class="title mt-2">3.1. {{$t('tenantFaq.s31')}} </h4>
        <div>
          {{$t('tenantFaq.t311')}}
        </div>
        <h4 class="title mt-2">3.2. {{$t('tenantFaq.s32')}} </h4>
        <div>
          {{$t('tenantFaq.t321')}}
        </div>
        <ul>
          <li>{{$t('tenantFaq.l3221')}}</li>
          <li>{{$t('tenantFaq.l3222')}}</li>
          <li>{{$t('tenantFaq.l3223')}}</li>
        </ul>
        <h4 class="title mt-2">3.3. {{$t('tenantFaq.s33')}} </h4>
        <div>
          {{$t('tenantFaq.t331')}}
        </div>
        <h4 class="title mt-2">3.4. {{$t('tenantFaq.s34')}} </h4>
        <div>
          {{$t('tenantFaq.t341')}}
        </div>
        <h4 class="title mt-2">3.5. {{$t('tenantFaq.s35')}} </h4>
        <div>
          {{$t('tenantFaq.t351')}}
        </div>
        <h3 class="headline mt-3">4. {{$t('tenantFaq.title4')}}</h3>
        <h4 class="title mt-2">4.1. {{$t('tenantFaq.s41')}} </h4>
        <div>
          {{$t('tenantFaq.t411')}}
        </div>
        <h4 class="title mt-2">4.2. {{$t('tenantFaq.s42')}} </h4>
        <div>
          {{$t('tenantFaq.t421')}}
        </div>
        <h3 class="headline mt-3">5. {{$t('tenantFaq.title5')}}</h3>
        <h4 class="title mt-2">5.1. {{$t('tenantFaq.s51')}} </h4>
        <div>
          {{$t('tenantFaq.t511')}}
        </div>
        <h4 class="title mt-2">5.2. {{$t('tenantFaq.s52')}} </h4>
        <div>
          {{$t('tenantFaq.t521')}}
        </div>
        <h4 class="title mt-2">5.3. {{$t('tenantFaq.s53')}} </h4>
        <div>
          {{$t('tenantFaq.t531')}}
        </div>
        <h3 class="headline mt-3">6. {{$t('tenantFaq.title6')}}</h3>
        <h4 class="title mt-2">6.1. {{$t('tenantFaq.s61')}} </h4>
        <div>
          {{$t('tenantFaq.t611')}}
        </div>
        <h4 class="title mt-2">6.2. {{$t('tenantFaq.s62')}} </h4>
        <div>
          {{$t('tenantFaq.t621')}}
        </div>
      </div>

    </v-flex>

  </v-layout>


</div>
</template>
<script>
export default {
  name: "",
  data: () => ({
    showVerification: false,
    items: [{
        text: 'Home',
        disabled: false,
        href: '/'
      },
      {
        text: 'FAQ',
        disabled: false,
        href: '/faq'
      },
      {
        text: 'Tenants',
        disabled: true,
        href: '/faq/tenants'
      }
    ]
  }),
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }
}
</script>
<style lang="scss" scoped>
.how-it-works {
    text-align: justify;
}
.how-it-works div {

    margin: 1rem 0;
}

.link {
    color: #00bfff;
    text-decoration: underline;
    cursor: pointer;
}
</style>
