<template>
<div id="">
  <v-layout pa-3 wrap justify-center>
    <v-flex md8 sm9 xs10>
      <v-breadcrumbs divider="">
        <v-icon slot="divider">chevron_right</v-icon>
        <v-breadcrumbs-item v-for="(item,n) in items" :key="n" :disabled="item.disabled">
          <a :href="item.href">{{ item.text }}</a>
        </v-breadcrumbs-item>
      </v-breadcrumbs>
      <div class="how-it-works">

        <h3 class="headline mt-3">1. {{$t('llFaq.title1')}} </h3>
        <h4 class="title mt-2">1.1. {{$t('llFaq.st11')}} </h4>
        <div>
          {{$t('llFaq.t111')}}
        </div>
        <div>
          {{$t('llFaq.t112')}}
        </div>
        <div>
          {{$t('llFaq.t113')}}
        </div>
        <v-btn color="primary" :href="'/aboutus'">{{$t('llFaq.l114')}}</v-btn>
        <h4 class="title mt-2">1.2. {{$t('llFaq.st12')}} </h4>
        <div>
          {{$t('llFaq.t121')}}
        </div>
        <div>
          {{$t('llFaq.t122')}}
        </div>
        <div>
          {{$t('llFaq.t123')}}
        </div>
        <div>
          {{$t('llFaq.t124')}}
        </div>
        <div>
          {{$t('llFaq.t125')}}
        </div>
        <h4 class="title mt-2">1.3. {{$t('llFaq.st13')}} </h4>
        <div>
          {{$t('llFaq.t131')}}
        </div>
        <ul>
          <li>{{$t('llFaq.l132')}}</li>
          <li>{{$t('llFaq.l133')}}</li>
          <li>{{$t('llFaq.l134')}}</li>
          <li>{{$t('llFaq.l135')}}</li>
        </ul>
        <div>
          {{$t('llFaq.t136')}}
        </div>
        <h4 class="title mt-2">1.4. {{$t('llFaq.st14')}} </h4>
        <div>
          {{$t('llFaq.t141')}}
        </div>
        <div>
          {{$t('llFaq.t142')}}
        </div>
        <h3 class="headline mt-3 mt-2">2. {{$t('llFaq.title2')}}</h3>
        <h4 class="title mt-2">2.1. {{$t('llFaq.st21')}} </h4>
        <div>
          {{$t('llFaq.t211')}}
        </div>
        <h4 class="title mt-2">2.2. {{$t('llFaq.st22')}} </h4>
        <div>
          {{$t('llFaq.t221')}}
        </div>
        <div>
          {{$t('llFaq.t222')}}
        </div>
        <h3 class="headline mt-3">3. {{$t('llFaq.title3')}}</h3>
        <h4 class="title mt-2">3.1. {{$t('llFaq.st31')}} </h4>
        <div>
          {{$t('llFaq.t311')}}
        </div>
        <div>
          {{$t('llFaq.t312')}}
        </div>
        <div>
          {{$t('llFaq.t313')}}
        </div>
        <div>
          {{$t('llFaq.t314')}}
        </div>
        <h4 class="title mt-2">3.2. {{$t('llFaq.st32')}} </h4>
        <div>
          {{$t('llFaq.t321')}}
        </div>
        <h4 class="title mt-2">3.3. {{$t('llFaq.st33')}} </h4>
        <div>
          {{$t('llFaq.t331')}}
        </div>
        <div>
          {{$t('llFaq.t332')}}
        </div>
        <h3 class="headline mt-3">4. {{$t('llFaq.title4')}}</h3>
        <h4 class="title mt-2">4.1. {{$t('llFaq.st41')}} </h4>
        <div>
          {{$t('llFaq.t411')}}
        </div>
        <h4 class="title mt-2">4.2. {{$t('llFaq.st42')}} </h4>
        <div>
          {{$t('llFaq.t421')}}
        </div>
        <div>
          {{$t('llFaq.t422')}}
        </div>
        <div class="">
          <span><b>{{$t('llFaq.tu423')}}</b> {{$t('llFaq.t424')}}</span>
        </div>
        <ul>
          <li>{{$t('llFaq.li4241')}}</li>
          <li>{{$t('llFaq.li4242')}}</li>
          <li>{{$t('llFaq.li4243')}}</li>
        </ul>
        <div class="">
          <span><b>{{$t('llFaq.tu425')}}</b> {{$t('llFaq.t424')}}</span>
        </div>
        <ul>
          <li>{{$t('llFaq.li4261')}}</li>
          <li>{{$t('llFaq.li4262')}}</li>
          <li>{{$t('llFaq.li4263')}}</li>
        </ul>
        <div class="">
          <span><b>{{$t('llFaq.tu427')}}</b> {{$t('llFaq.t424')}}</span>
        </div>
        <ul>
          <li>{{$t('llFaq.li4281')}}</li>
          <li>{{$t('llFaq.li4282')}}</li>
          <li>{{$t('llFaq.li4283')}}</li>
        </ul>
        <div class="">
          <span><b>{{$t('llFaq.tu429')}}</b> {{$t('llFaq.t4210')}}</span>
        </div>
        <h4 class="title mt-2">4.3. {{$t('llFaq.st43')}} </h4>
        <div>
          {{$t('llFaq.t431')}}
        </div>
        <div>
          {{$t('llFaq.t432')}}
        </div>
        <div>
          {{$t('llFaq.t433')}}
        </div>
        <div>
          {{$t('llFaq.t434')}}
        </div>
        <div>
          {{$t('llFaq.t435')}}
        </div>
        <div>
          {{$t('llFaq.t436')}}
        </div>
        <h4 class="title mt-2">4.4. {{$t('llFaq.st44')}} </h4>
        <div>
          {{$t('llFaq.t441')}}
        </div>
        <div>
          {{$t('llFaq.t442')}}
        </div>
        <div>
          {{$t('llFaq.t443')}}
        </div>
      </div>
    </v-flex>
  </v-layout>
</div>
</template>
<script>
export default {
  name: "",
  data: () => ({
    showVerification: false,
    items: [{
        text: 'Home',
        disabled: false,
        href: '/'
      },
      {
        text: 'FAQ',
        disabled: false,
        href: '/faq'
      },
      {
        text: 'Landlords',
        disabled: true,
        href: '/faq/landlords'
      }
    ]
  }),
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }
}
</script>
<style lang="scss" scoped>
.search-row {
    background: rgba(255,255,255,0),;
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
}
.blog-title {
    margin: auto;
    padding-top: 15vh;
    margin-bottom: 10vh;
    t-align: center;
    color: #ffffff;
    t-shadow: 0 2px 4px rgba(0,0,0,.6);
}
.how-it-works {
    t-align: justify;
}
.how-it-works div {

    margin: 1rem 0;
}

.link {
    color: #00bfff;
    t-decoration: underline;
    cursor: pointer;
}
</style>
